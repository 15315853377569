import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import ProfileImage from "../../../assets/images/half-no-bg.png";
import Button from "@mui/material/Button";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as actionTypes from "../../../ReduxManager/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileEditPage from "../ProfileEditPage";
import defaultPfp from "../../../assets/images/svg/default-pfp.svg"

const Header = ({
  isMobileDrawerOpen,
  isSidebarToggledOpen,
  handleDrawerToggle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const userData = useSelector((state) => state.userData);
  const themeColor = userData.userTheme || "#8c59a3";
  const profileUrl = userData.profileImg
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const handleCloseProfileEdit = () => setShowProfileEdit(false);
  const handleShowProfileEdit = () => {
    setShowProfileEdit(true);
  }

  //-----------------------------------
  // Admin Logout functionality
  //--------------------------------------
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogout = () => {
    // console.log("admin logout functions");
    // Clear the entire Redux state
    dispatch({ type: actionTypes.CLEAR_REDUX_STATE });
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          background: isScrolled ? themeColor : themeColor,
          borderBottom: "1px solid #FFFFFF1A",
          boxShadow: isScrolled ? '5px 7px 26px -5px #502965' : 'none',
          zIndex: 3300,
        }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            style={{
              marginLeft:
                window.innerWidth <= 800 ? 0 : isSidebarToggledOpen ? 240 : 40,
            }}
          >
            {(isMobileDrawerOpen || isSidebarToggledOpen) ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="inherit">
              { profileUrl ? (<img
                alt="Profile Picture"
                src={profileUrl}
                style={{ width: 40, height: 40, cursor: "pointer", objectFit: "cover", borderRadius: "23px" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />) : (
                <img
                alt="Profile Picture"
                src={defaultPfp}
                style={{ width: 40, height: 40, cursor: "pointer", objectFit: "cover", borderRadius: "23px" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
              )}

              <Menu
                style={{ marginTop: 8, marginRight: 16 }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    width: '200px',
                    padding: 0
                  }
                }}

              >
                <div style={{ fontSize: 14, fontWeight: 500, padding: 10, textAlign: 'center', color: '#fff', background: themeColor }}><span>Welcome <br />{userData.username}</span>
                </div>

                <MenuItem sx={{ display: 'flex', justifyContent: 'center', gap: 1, fontWeight: 400, marginTop: 1, marginBottom: 1, fontSize: 14, color: themeColor }} onClick={handleShowProfileEdit}><AccountCircleIcon/>Profile</MenuItem>
                <MenuItem sx={{ display: 'flex', justifyContent: 'center', gap: 1, fontWeight: 400, marginTop: 1, marginBottom: 1, fontSize: 14, color: themeColor }} onClick={adminLogout}><LogoutIcon></LogoutIcon>Logout</MenuItem>

              </Menu>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <ProfileEditPage showProfileEdit={showProfileEdit}
        handleCloseProfileEdit={handleCloseProfileEdit} />
    </>
  );
};

export default Header;
