import React, { useEffect } from "react";
import "./assets/styles/global.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthLoginPage from "./AuthPages/AuthLoginPage";
import PrivateRouter from "./Utils/PrivateRouter";
import MainLayout from "./Layout/MainLayout";
import SuspenseLoader from "./Utils/SuspenseLoader";
import AuthContextProvider from "../src/context/auth/authProvider";
import { useSelector, useDispatch } from "react-redux";
import PrivateRoutes from "./Routes/Routes";
import Error404Page from "./Pages/Error404Page";
import axiosInstance from "./axios";
import { setSidebarData } from "./ReduxManager/actions";

function App() {


  // ------------------- Get Sidebar Data -------------------------
  // Hook for dispatching actions to Redux store
  const dispatch = useDispatch();
  useEffect(() => {
    const getSideBarType = async () => {
      try {
        const response = await axiosInstance.get(`/sidebar/get`)
        // console.log("sidebar", response.data);
        dispatch(setSidebarData(response.data.data));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getSideBarType();
  }, []);

  // Access the isAuthenticated and userAccessUrls state from the Redux store
  const userAccessUrls = useSelector((state) => state.userData.userAccessUrls);
  const userData = useSelector((state) => state.userData);
  const themeColor = userData.userTheme;

  document.documentElement.style.setProperty('--theme-color', themeColor);

  // Filter the routes to include only those that match userAccessUrls
  const filteredRoutes = PrivateRoutes.filter((route) => {
    const routeKey = route.path.split("/").pop();
    return userAccessUrls.includes(routeKey);
  });

  return (
    <div className="app">
      <AuthContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route element={<PrivateRouter />}>
              <Route element={<MainLayout />}>
                {filteredRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <React.Suspense fallback={<SuspenseLoader />}>
                        {route.element}
                      </React.Suspense>
                    }
                  >
                    {route.children &&
                      route.children.map((childRoute, idx) => (
                        <Route
                          key={idx}
                          path={childRoute.path}
                          element={
                            <React.Suspense fallback={<SuspenseLoader />}>
                              {childRoute.element}
                            </React.Suspense>
                          }
                        />
                      ))}
                  </Route>
                ))}
              </Route>
            </Route>
            <Route path="/login" element={<AuthLoginPage />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </BrowserRouter>
      </AuthContextProvider>
    </div>
  );
}

export default App;
